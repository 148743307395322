
import { Divider } from "@suid/material";
import { AccountMenu } from '../components/AppMenuBar/AppBarMenu';
import { ShowHeading } from '../components/SmallComponents/SmallHeader'
import { CustomerCandidateComponent} from '../components/Customer/CustomerCandidateComponent'
import { getText } from '../components/SmallComponents/Translate'
import { useNavigate } from "@solidjs/router";
import { isLoggedIn } from '../../utils/isloggedin'

const ShowCustomerCVPage = () => {

    const navigate = useNavigate();

    if (!isLoggedIn()) {
      navigate('/login');
      return;
    }

    const txtHeader = getText("candidatepage", "header")
    return (
        <>
            <AccountMenu />
            <ShowHeading  pxValue={4} >{txtHeader()}</ShowHeading>
            <Divider />
            <CustomerCandidateComponent />
        </>
    );
}
export {ShowCustomerCVPage}
